.about {
  padding-top: 60px;

  h2 {
    font-size: 1.2rem;
    font-family: var(--header-font);
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 0;
  }
}

.summary {
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.summaryMain {
  width: 60%;

  button {
    background-color: var(--highlight-color);
    border: none;
    color: #fff;
    font-size: 1rem;
    padding: 0.4rem 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 40px;

    &:hover {
      background-color: #1c7aed;
    }

    &:active {
      background-color: #0d53a8;
    }
  }
}

.summarySkills {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 30%;

  li {
    font-size: 0.8rem;
    padding: 0.5rem;
    background-color: #252525;
    margin: 0 0.5rem 0.5rem 0;
    border-radius: 4px;
  }
}

.summaryExperiences {
  background-color: #1b1b1b;
  padding: 60px 0;

  h2 {
    margin-bottom: 1rem;
  }
}

.summaryExperience {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 -2rem;
  position: relative;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
  }

  p {
    margin: 0;
  }

  &::before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    position: absolute;
    background-color: #454545;
    z-index: 2;
    top: .4rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    border-right: 2px dashed #303030;
    z-index: 1;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.summaryExperienceColumn {
  width: 50%;
  padding: 0 2rem;
}