.workDetails {
  h2 {
    font-size: 1.2rem;
    font-family: var(--header-font);
    font-weight: bold;
    letter-spacing: 1px;
  }

  :global(.tag) {
    background-color: #252525;
  }

  li {
    color: #bababa;
  }
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h2 {
    margin-top: 0;
  }
}

.link {
  padding: 0.4rem 1rem;
  border-radius: 6px;
  display: inline-block;

  &:hover {
    background-color: #252525;
  }
}

.hero_container {
  position: relative;
}

.hero_bg {
  padding: 0;
  margin: 0;

  img {
    height: auto;
    width: 100%;
    vertical-align: middle;
  }
}

.hero_details {
  padding-bottom: 60px;
}

.content_container {
  padding: 60px 0;
}