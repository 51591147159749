.worksHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h2 {
    font-size: 1.2rem;
    font-family: var(--header-font);
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
  }
  
  a {
    font-size: .8rem;
  }
}
