.hero {
  h1 {
    font-family: var(--header-font);
    font-weight: bold;
    font-size: 1.6rem;
    letter-spacing: 1px;
    margin-bottom: 2rem;

    span {
      color: var(--highlight-color);
    }
  }

  p {
    font-size: 1.2rem;

    span {
      color: #fff;
    }
  }
}

.social {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;

  li {
    margin-right: 1rem;

    a {
      opacity: 0.9;
      transition: all 0.3s ease;
      width: 1.6rem;
      height: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
        background-color: var(--highlight-color);
      }

      &:global(.linkedin) {
        &:hover,
        &:active,
        &:focus {
          background-color: #007bb6;
          color: #fff;
        }
      }
      &:global(.gitlab) {
        &:hover,
        &:active,
        &:focus {
          background-color: #fe651a;
          color: #fff;
        }
      }
    }
  }
}
