.talk {
  width: 100%;
  background-color: #252525;
  border-radius: 6px;
  padding: 60px 0;

  .content {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h2 {
    font-size: 1rem;
    font-family: var(--header-font);
    margin-top: 0;
  }

  a {
    color: #fff;
  }
}
