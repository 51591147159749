.works {
  padding: 60px 0;
  
  h2 {
    font-size: 1.2rem;
    font-family: var(--header-font);
    font-weight: bold;
    letter-spacing: 1px;
  }
}
