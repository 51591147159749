/* Variables */
:root {
  --header-font: 'Montserrat', sans-serif;
  --highlight-color: #1066cf;
  --background-color: #181818;
  --lazy-color: #bababa;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

html {
  font-size: 20px;
}

body {
  margin: 0;
  font-family: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: var(--background-color);
  font-size: 1rem;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
}

*::after,
*::before {
  box-sizing: inherit;
}

a {
  color: #bababa;
  text-decoration: none;
}

a:hover,
a:focus,
a:active,
a.active {
  color: #fff;
}

p {
  color: #bababa;
}
