.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: var(--background-color);
}

.wrapper {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
