.brand {
  font-family: var(--header-font);
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
}

.brand span {
  color: var(--highlight-color);
}