.workInProgress {
  min-height: 800px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: flex-start;

  p {
    color: #fff;
    font-size: 2rem;
    margin: 0;

    &:last-of-type {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  button {
    background-color: var(--highlight-color);
    border: none;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    padding: .6rem 1.5rem;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #1c7aed;
    }

    &:active {
      background-color: #0d53a8;
    }
  }
}


.status {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  opacity: 0.02;

  span {
    font-size: 36vw;
    font-weight: 600;
    font-family: var(--header-font);
  }
}