.footer {
  display: block;
  width: 100%;
  padding: 60px 0;
  background-color: #161616;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  p {
    font-size: 0.8rem;
    margin: 0;

    span {
      color: var(--highlight-color);
    }
    &:first-child {
      color: #fff;
      font-family: var(--header-font);
      font-weight: bold;
      letter-spacing: 1px;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.social {
  p {
    font-size: 0.8rem;
    margin: 0;
    margin-bottom: 1rem;

    a {
      color: #fff;
    }
  }

  ul {
    margin: 0;
  }
}
