.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 2rem;
}

.gridItem {
  aspect-ratio: 1;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.gridWrapper {
  display: block;
  padding: 1rem;
}

.gridBg {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
  cursor: pointer;

  figure {
    padding: 0;
    margin: 0;
  }

  img {
    height: auto;
    width: 100%;
    vertical-align: middle;
    box-shadow: 0 8px 24px rgb(0 0 0 / 50%);
  }
}

.gridOverlay {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
  background-image: linear-gradient(180deg,#00000057 0,rgba(0,0,0,.338) 3.5%,rgba(0,0,0,.324) 7%,rgba(0,0,0,.306) 10.35%,rgba(0,0,0,.285) 13.85%,rgba(0,0,0,.262) 17.35%,rgba(0,0,0,.237) 20.85%,rgba(0,0,0,.213) 24.35%,rgba(0,0,0,.188) 27.85%,rgba(0,0,0,.165) 31.35%,rgba(0,0,0,.144) 34.85%,rgba(0,0,0,.126) 38.35%,rgba(0,0,0,.112) 41.85%,rgba(0,0,0,.103) 45.35%,#0000001a 48.85%,rgba(0,0,0,.103) 52.35%,rgba(0,0,0,.112) 55.85%,rgba(0,0,0,.126) 59.35%,rgba(0,0,0,.144) 62.85%,rgba(0,0,0,.165) 66.35%,rgba(0,0,0,.188) 69.85%,rgba(0,0,0,.213) 73.35%,rgba(0,0,0,.237) 76.85%,rgba(0,0,0,.262) 80.35%,rgba(0,0,0,.285) 83.85%,rgba(0,0,0,.306) 87.35%,rgba(0,0,0,.324) 90.85%,rgba(0,0,0,.338) 94.35%,rgba(0,0,0,.347) 97.85%,#00000059);
  transition: all 0.3s ease;

  &:global(.active) {
    opacity: 1;
  }

  :global(.top) {
    display: flex;
    justify-content: flex-end;

    a {
      padding: 0.4rem;
      background: #fff;
      line-height: 1;
      width: 40px;
      height: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.6);

      &:hover {
        background: #1b1b1b;
      }
    }
  }
}

.gridDetails {
  p {
    font-size: 1rem;
    font-family: var(--header-font);
    font-weight: 500;
    color: #fff;
    margin: 0;
  }
}
