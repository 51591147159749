.tags {
  span {
    font-size: 0.6rem;
    font-weight: 300;
    text-transform: uppercase;
    padding: 0.4rem;
    margin-right: 0.3rem;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 6px;
  }
}