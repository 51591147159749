.links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.link {
  margin-left: 2rem;
}

.link a {
  opacity: 0.7;
  transition: all 0.3s ease;
  position: relative;

  // &::after {
  //   opacity: 0;
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   left: 50%;
  //   bottom: -10px;
  //   transform: translateX(-50%);
  //   width: 5px;
  //   height: 5px;
  //   border-radius: 5px;
  //   background-color: var(--highlight-color);
  //   transition: all 0.3s ease;
  //   z-index: -1;
  // }
}

.link a:global(.active),
.link a:hover,
.link a:focus,
.link a:active {
  opacity: 1;
}
